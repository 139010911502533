#changingProgram{
min-height:200vh;
width:100%;
min-width:100%;
z-index:-1;
height:auto;
}
.changingProgramImg{
height:100vh;
min-height:30vh;
width:100%;
min-width:100%;
background-color:white;
display:flex;
}
.changingProgramText{
    height: auto;
    min-height: 100vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    color:black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.changingProgramText p{
margin-top:0;
padding-top:0;
padding-left:50px;
}

#centeredProgramImg{
background-image: url(./ProgramHero.svg);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 80%;
    min-height: 100%;
    width: 80%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items:ł center;
    justify-content: center;
    margin-left: auto;
    margin-right:auto;
}
.centeredInnerBody{
height:auto;
display:flex;
width:80%;
position:relative;
flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
padding-bottom:10%;
overflow:hidden;
}
.programTitleSubtitle{
    margin: 2.25em 0;
    text-align: center;
    width:100%;
}
.programTitleSubtitle h2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #009ba4;
}
.programTitleSubtitle h1{
font-size: 1.3em;
    color: #009ba4;
}
.centeredInnerBody h2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #009ba4;
}
.centeredInnerBody h1{
    font-size: 1.3em;
    color: #009ba4;
}
.programIntroText{
    clear: both;
    vertical-align: top;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top:20vh;
}
#programIntroLeft{
width: 48%;
 margin-right: auto;
}
#programIntroRight{
width: 48%;
 margin-right: auto;
}
.animatedProgram{
display:flex;
flex-direction:row;
flex-wrap: wrap;
}
.animatedImg{
}
.animatedImg a{
    position:relative;
    display: table-cell;
    vertical-align: middle;
    float: none;
    margin: 0;
    padding: 3em 0;
}
.animatedTextOuter{
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 56.0%;
    margin: 0;
    padding: 4.5em 4% 4.5em 0;
}
@keyframes right-center {
0% {left: 50vw;
}
100% {left:0vw; }
}
@keyframes left-center {
0% {right: 50vw;}
100% {right: 0vw;}
}
.animatedText{
max-width:40vw;
}
.animatedText p{
padding-left:0px;
}
.noUnder{
text-decoration:none;
}
.blueh4{
    font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
@media only screen and (max-width: 500px){
.centeredHomeImage{
height:40vh!Important;
}
.changingLecturingImg{
height:35vh!important;
}
.changingProgramImg{
height:30vh!important;
}
.changingAboutImg{
height:40vh!important;
}
.ChangingContactImg{
height:37vh!important;
}
}
@media (min-width: 500px) and (max-width: 600px){
.centeredHomeImage{
height:40vh!important;
}
.changingProgramImg{
height:45vh!Important;
}
.changingAboutImg{
height:50vh!important;
}
.ChangingContactImg{
height:40vh!important;
}
}
@media only screen and (max-width: 720px){
#changingHomeImg .react-reveal{
    margin-left: auto!important;
    margin-right: auto!important;
       padding-bottom: 20px;
}
#changingHomeImg .hvec{
    text-align: center;
    position: absolute!important;
    width: 100vw!important;
}
#changingHomeImg .blueh4{
text-align:center;
}
.first, .second, .third, .fourth{
    width: 100%!important;
    position: relative!important;
    text-align: center!important;
}
#changingHome p{
text-align:center!Important;
}
.first p, .second p, .third p, .fourth p{
text-align:center!important;
}
#HomeIntroLeft, #HomeIntroRight{
    width: 100%!important;
    text-align: center!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

#leftCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#centerCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#rightCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#programIntroLeft{
width:100%;
}
.About-left {
width: 100%!important;
    margin-right: auto!important;
    margin-left: auto!important;
    max-width: 100%!important;
}
.About-right{
width: 100vw!important;
    margin-right: auto!important;
    margin-left: auto!important;
    max-width: 100%!important;
}
#programIntroRight{
width:100%;
}
.centeredHomeImage{
height: 53vh;
}
.programIntroText{
padding-top:0!important;
}
.changingLecturingImg{
min-height:0!important;
}
.changingProgramImg{
height:53vh;
}
.imgP{
width:100%;
}
.animatedText{
max-width:100%!Important;
}
.changingAboutImg{
min-height:30vh!important;
}
.ChangingContactImg{
min-height:30vh!important;
height:45vh;
}
#alignText{
margin-right:auto;
margin-top:5vh;
}
#alignForm{
margin-right:auto;
}
.fInpt{
width: 80vw!Important;
}
}
@media (min-width: 700px) and (max-width: 800px){
.AnimatedImg img{
width:80%!important;
}
.react-reveal{
margin-left:0!important;
margin-right:0!important;
}
.imgP{
    width: 100vw;
    height: auto;
    margin-left: -10%;
}
.animatedTextOuter{
width:100%!important;
padding:0!important;
text-align:center!important;
}
.animatedText{
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.centeredHomeImage{
    height:62vh!important;
}
.changingProgramImg{
    height:57vh!important;
}
}
@media (min-width: 800px) and (max-width: 900px){
.centeredHomeImage{
height:77vh!important;
}
.changingProgramImg{
height:70vh!important;
}
}
@media (min-width: 900px) and (max-width: 1125px){
.react-reveal{
margin-left:auto;
margin-right:auto;
}
.programIntroText{
padding-top:0!important;
}
.centeredHomeImage{
height:90vh;
}
}
