#info{
width:100%;
height:15%;
display:flex;
flex-wrap:wrap;
justify-content: space-between;
background-color: rgb(0, 152, 152);
}
.download{
text-align:center;
width:40%;
min-height:5vh;
}
.download a{
    position: relative;
    top: 0.5vh;
    bottom: 0.5vh;
    text-decoration: none;
    color: white;
    font-weight: 500;
}
.downloads{
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
}
#Bprogramming{
    width: 100%;
    height: 30px;
    background-color: black;
    color: white;
    text-align: center;
}
#Bprogramming a{
color:white;
text-decoration:none;
font-weight:500;
top:5px;
bottom:5px;
position:relative;
line-height:0.8em;
}
