.backdrop {
 position: fixed;
 width: 100%;
 height: 100%;
top: 0px;
left: 0px;
background: rgba(0,0,0,0.3);
z-index:100;

}
