#changingLecturing{
width:100%;
min-width:100%;
min-height:100vh;
background-color: rgb(0, 152, 152);
color:white;
}
.changingLecturingImg{
height: 50vh;
min-height: 60vh;
background-color:white;
width: 100%;
min-width: 100%;
background-image: url(./LecturingHero.svg);
background-repeat: no-repeat;
background-size: contain;
display: flex;
}
.changingLecturingText{
width:100%;
min-width:100%;
color: white;
}
.changingLecturingText p{
margin:0;
padding-left:50px;
padding-top:0px;
padding-bottom:0px;
}
.lecturingOptions{
display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top:8rem;
}
.potek{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
}
.cilji{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
}
.potek p, .cilj p{
margin:0;
padding: 0;
}
.solanjeh2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: white;
    padding-top:1em;
}
.cntr{
width:100%;
text-align:center;
color:white!important;
}
.icn{
text-align: center;
}
.svg-inline--fa{
font-size:4em;
}
.renderedOptions{
padding-top: 6rem;
}
.innerOption:hover{
cursor:pointer;
}
.solanjeh4{
    text-align: center;
    padding-top: 1em;
}
