.toolbar {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background: #009898;
    height: 56px;
    z-index: 1;
}
.bpp{
padding-left:20px;
}

.toolbar__logo {
 margin-left: 0.5rem; 
width:auto;
min-width:230px;
}
.toolbar__logo a {
color:white;
text-decoration: none;
font-size: 1.5rem;
}
.toolbar__logo img{
    width: auto;
    height: 50px;
    padding-top: 3px;
}
.toolbar__navigation{
display:flex;
align-items: center;
height: 100%;
padding: 0 1rem;
}
.toolbar_navigation-items ul {
 list-style: none;
margin: 0;
padding: 0;
display: flex;
}
.toolbar_navigation-items li{
 padding: 0 0.5rem;
 
}

.toolbar_navigation-items a{
 color:white;
text-decoration: none;
}
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
color: black;
}
.spacer {
flex: 1;
}
@media (max-width: 800px) {
 .toolbar_navigation-items {
            display:none;
}
}
@media (min-width: 800px) {
 .toggle-button {
             display:none; 
}
 .toolbar__logo{
     margin-left: 0;
}
}
