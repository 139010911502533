#changingAbout{
min-height:200vh;
width:100%;
min-width:100%;
z-index:-1;
}
.changingAboutText{
    height: auto;
    min-height: 100vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    color:black;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.changingAboutImg{
height:70vh;
min-height:60vh;
width:100%;
min-width:100%;
background-image: url(./About.svg);
background-repeat: no-repeat;
background-size: contain;
display:flex;
}

.changingAboutText p{
padding-left:0;
}
.aboutTitleSubtitle{
position:relative;
margin-top:auto;
}
.About-left{
width: 48%;
margin-right:auto;
max-width:50%;
}
.About-right{
width:48%;
margin-left:auto;
max-width:50%;
}
.apointment{
    width: 100%;
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    flex-direction: row;
    background: #009898;
    color:white!important;
    padding-top: 10%;
    padding-bottom: 10%;
}
.innerApointment{
margin: auto;
text-align: center;
color:white;
}
.innerApointment a{
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    text-shadow: none;
    background: none;
    box-shadow: none;
        display: inline-block;
    padding: 0.64286em 1.42857em;
    outline: none;
    font-size: 1em;
    line-height: 1.42929;
    cursor:pointer;
    text-decoration: none;
}
.counters{
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}
#leftCounter{
    margin-right: auto;
    width:30%;
}
#rightCounter{
   margin-left: auto;
   width:30%;
}
#centerCounter{
   margin-left:auto;
   margin-right:auto;
   width:30%;
}
#leftCounter span, #rightCounter span, #centerCounter span{
    font-size: 4.5em;
    line-height: 1;
    color: #b1dde1;
    font-weight: 600;
    display: block;
    text-align: center;
}
.textCounterAlign{
text-align:center;
}
.facts{
    width: 100%;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
}
.thin{
font-weight:600;
}
.blueh3{
    font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
.noPad{
padding-top:0!important;
}
