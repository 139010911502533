.articlesOuter{
    width: 100%;
    min-width: 100%;
    min-height: 81vh;
}
.articlesTitle{
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    color: rgb(0, 152, 152);
}
.articlesText{
    width: 80%;
    padding-top: 2%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3em;
}
