body {
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", sans-serif;
  color: #5c6667;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html {
height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
#toolbar{
width:100%;
}
switch{
max-width:100vw;
width:100%;
}
main{
position:relative;
padding-top:56px!important;
height:auto;
min-width:100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap:wrap;
          flex-wrap:wrap;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.toggle-button {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button__line {
  width: 30px;
  height: 2px;
  background: white;
}

.toolbar {
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    background: #009898;
    height: 56px;
    z-index: 1;
}
.bpp{
padding-left:20px;
}

.toolbar__logo {
 margin-left: 0.5rem; 
width:auto;
min-width:230px;
}
.toolbar__logo a {
color:white;
text-decoration: none;
font-size: 1.5rem;
}
.toolbar__logo img{
    width: auto;
    height: 50px;
    padding-top: 3px;
}
.toolbar__navigation{
display:-webkit-flex;
display:flex;
-webkit-align-items: center;
        align-items: center;
height: 100%;
padding: 0 1rem;
}
.toolbar_navigation-items ul {
 list-style: none;
margin: 0;
padding: 0;
display: -webkit-flex;
display: flex;
}
.toolbar_navigation-items li{
 padding: 0 0.5rem;
 
}

.toolbar_navigation-items a{
 color:white;
text-decoration: none;
}
.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active{
color: black;
}
.spacer {
-webkit-flex: 1 1;
        flex: 1 1;
}
@media (max-width: 800px) {
 .toolbar_navigation-items {
            display:none;
}
}
@media (min-width: 800px) {
 .toggle-button {
             display:none; 
}
 .toolbar__logo{
     margin-left: 0;
}
}

.side-drawer {
height: 100%;
background: #009898;
box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
position: fixed;
top: 0;
left: 0;
width: 70%;
max-width:400px;
z-index:200;
-webkit-transform: translateX(-100%);
        transform: translateX(-100%);
transition: -webkit-transform 0.3s ease-out;
transition: transform 0.3s ease-out;
transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.side-drawer.open {
 -webkit-transform:translateX(0);
         transform:translateX(0);
}
.side-drawer ul {
height:100%;
 list-style: none;
display: -webkit-flex;
display: flex;
-webkit-flex-direction:column;
        flex-direction:column;
-webkit-justify-content: center;
        justify-content: center;
}
.side-drawer li{
margin: 0.5rem 0;
}
.side-drawer a{
color: white;
text-decoration: none;
font-size: 1.2rem;
}
.side-drawer a:hover,
.side-drawer a:active {
 color:orange;
}
@media (min-width: 800) {
 .side-drawer {
 display:none;
}
}

.backdrop {
 position: fixed;
 width: 100%;
 height: 100%;
top: 0px;
left: 0px;
background: rgba(0,0,0,0.3);
z-index:100;

}

#changingHome{
width: 100%;
min-width:100%;
min-height:200vh;
height:auto;
overflow:hidden;
}
#changingHome p{
padding: 0;
margin: 0;
text-align:left;
}
.homee{
padding-top:12vh;
}
#changingHome h4{
font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
.ChangingImg{
width:100%;
min-width:100%;
min-height:100vh;
display: -webkit-flex;
display: flex;
}
.centeredHomeImage{
    height: 100vh;
    min-height: 30vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    display: -webkit-flex;
    display: flex;    

}
.changingHomeText{
min-width:80%;
max-width:100%;
min-height:100vh;
background-color:white;
color:black;
overflow:hidden;
}
.changingText p{
padding-top:5%;
text-align:center;
}
.changingText a{
    color: #009ba4;
    position: relative;
    text-decoration: none;
}
.lst{
 margin-left: auto;
    margin-right: auto;
    width: 430px;  
}
.news{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
#VseNovice{
    color: #009ba4;
    display: block;
    text-decoration: none;
    padding: 10px;
    padding-top: 50px;
    text-align: center;
}
#HomePackagesImage{
background-image: url(/static/media/Podnaslovna.59da236a.svg);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 80%;
    min-height: 100%;
    width: 80%;
    height: 100%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.pdingTop{
padding-bottom:0%!important;
padding-top:10%;
}
#HomeIntroLeft{
    width: 48%;
    margin-right: auto;
}
#HomeIntroRight{
    width: 48%;
    margin-left: auto;
}
.first, .second, .third, .fourth{
    width:20%;
    text-align:center;
    border-top: 7px solid #e5f5f6;
}
.news a{
text-decoration:none;
color:#009898;
}
#hhero{
max-width: 100%;
    height: auto;
}

#changingContact{
min-width:100%;
background-color: #009ba4;
color: white;
min-height:100vh;
}
.changingContactText{
width:80%;
min-height:auto;
background-color: #009ba4;
color: white;;
display: -webkit-flex;;
display: flex;
-webkit-flex-wrap: wrap;
        flex-wrap: wrap;
-webkit-justify-content: space-between;
        justify-content: space-between;
padding-right:10%;
padding-left:10%;
}
.changingContactText p{
margin:0;
padding: 0;
}
.ChangingContactImg{
    height: 50vh;
    min-height: 60vh;
    width: 100%;
    min-width: 100%;
    background-color:white;
    background-image: url(/static/media/Contact.8daf9867.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: -webkit-flex;
    display: flex;
}
.fInpt{
width:30vw;
}
#alignForm{
margin-top:5vh;
}
#alignText{
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
}
@media only screen and (max-width: 730px) {
#alignText{
margin-right:auto;
}
#alignForm{
margin-right:auto;
width: 80vw!Important;
}
}

#changingLecturing{
width:100%;
min-width:100%;
min-height:100vh;
background-color: rgb(0, 152, 152);
color:white;
}
.changingLecturingImg{
height: 50vh;
min-height: 60vh;
background-color:white;
width: 100%;
min-width: 100%;
background-image: url(/static/media/LecturingHero.39e0cd97.svg);
background-repeat: no-repeat;
background-size: contain;
display: -webkit-flex;
display: flex;
}
.changingLecturingText{
width:100%;
min-width:100%;
color: white;
}
.changingLecturingText p{
margin:0;
padding-left:50px;
padding-top:0px;
padding-bottom:0px;
}
.lecturingOptions{
display: -webkit-flex;
display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding-top:8rem;
}
.potek{
    width: 100%;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}
.cilji{
    width: 100%;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}
.potek p, .cilj p{
margin:0;
padding: 0;
}
.solanjeh2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: white;
    padding-top:1em;
}
.cntr{
width:100%;
text-align:center;
color:white!important;
}
.icn{
text-align: center;
}
.svg-inline--fa{
font-size:4em;
}
.renderedOptions{
padding-top: 6rem;
}
.innerOption:hover{
cursor:pointer;
}
.solanjeh4{
    text-align: center;
    padding-top: 1em;
}

#changingProgram{
min-height:200vh;
width:100%;
min-width:100%;
z-index:-1;
height:auto;
}
.changingProgramImg{
height:100vh;
min-height:30vh;
width:100%;
min-width:100%;
background-color:white;
display:-webkit-flex;
display:flex;
}
.changingProgramText{
    height: auto;
    min-height: 100vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    color:black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.changingProgramText p{
margin-top:0;
padding-top:0;
padding-left:50px;
}

#centeredProgramImg{
background-image: url(/static/media/ProgramHero.59da236a.svg);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 80%;
    min-height: 100%;
    width: 80%;
    height: 100%;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items:ł center;
            align-items:ł center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-left: auto;
    margin-right:auto;
}
.centeredInnerBody{
height:auto;
display:-webkit-flex;
display:flex;
width:80%;
position:relative;
-webkit-flex-wrap: wrap;
        flex-wrap: wrap;
margin-left: auto;
margin-right: auto;
padding-bottom:10%;
overflow:hidden;
}
.programTitleSubtitle{
    margin: 2.25em 0;
    text-align: center;
    width:100%;
}
.programTitleSubtitle h2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #009ba4;
}
.programTitleSubtitle h1{
font-size: 1.3em;
    color: #009ba4;
}
.centeredInnerBody h2{
    margin: 0;
    font-size: 1.4em;
    font-weight: bold;
    color: #009ba4;
}
.centeredInnerBody h1{
    font-size: 1.3em;
    color: #009ba4;
}
.programIntroText{
    clear: both;
    vertical-align: top;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top:20vh;
}
#programIntroLeft{
width: 48%;
 margin-right: auto;
}
#programIntroRight{
width: 48%;
 margin-right: auto;
}
.animatedProgram{
display:-webkit-flex;
display:flex;
-webkit-flex-direction:row;
        flex-direction:row;
-webkit-flex-wrap: wrap;
        flex-wrap: wrap;
}
.animatedImg{
}
.animatedImg a{
    position:relative;
    display: table-cell;
    vertical-align: middle;
    float: none;
    margin: 0;
    padding: 3em 0;
}
.animatedTextOuter{
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 56.0%;
    margin: 0;
    padding: 4.5em 4% 4.5em 0;
}
@-webkit-keyframes right-center {
0% {left: 50vw;
}
100% {left:0vw; }
}
@keyframes right-center {
0% {left: 50vw;
}
100% {left:0vw; }
}
@-webkit-keyframes left-center {
0% {right: 50vw;}
100% {right: 0vw;}
}
@keyframes left-center {
0% {right: 50vw;}
100% {right: 0vw;}
}
.animatedText{
max-width:40vw;
}
.animatedText p{
padding-left:0px;
}
.noUnder{
text-decoration:none;
}
.blueh4{
    font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
@media only screen and (max-width: 500px){
.centeredHomeImage{
height:40vh!Important;
}
.changingLecturingImg{
height:35vh!important;
}
.changingProgramImg{
height:30vh!important;
}
.changingAboutImg{
height:40vh!important;
}
.ChangingContactImg{
height:37vh!important;
}
}
@media (min-width: 500px) and (max-width: 600px){
.centeredHomeImage{
height:40vh!important;
}
.changingProgramImg{
height:45vh!Important;
}
.changingAboutImg{
height:50vh!important;
}
.ChangingContactImg{
height:40vh!important;
}
}
@media only screen and (max-width: 720px){
#changingHomeImg .react-reveal{
    margin-left: auto!important;
    margin-right: auto!important;
       padding-bottom: 20px;
}
#changingHomeImg .hvec{
    text-align: center;
    position: absolute!important;
    width: 100vw!important;
}
#changingHomeImg .blueh4{
text-align:center;
}
.first, .second, .third, .fourth{
    width: 100%!important;
    position: relative!important;
    text-align: center!important;
}
#changingHome p{
text-align:center!Important;
}
.first p, .second p, .third p, .fourth p{
text-align:center!important;
}
#HomeIntroLeft, #HomeIntroRight{
    width: 100%!important;
    text-align: center!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

#leftCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#centerCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#rightCounter{
margin-right: auto!important;
    margin-left: auto!important;
    width: 100%!important;
}
#programIntroLeft{
width:100%;
}
.About-left {
width: 100%!important;
    margin-right: auto!important;
    margin-left: auto!important;
    max-width: 100%!important;
}
.About-right{
width: 100vw!important;
    margin-right: auto!important;
    margin-left: auto!important;
    max-width: 100%!important;
}
#programIntroRight{
width:100%;
}
.centeredHomeImage{
height: 53vh;
}
.programIntroText{
padding-top:0!important;
}
.changingLecturingImg{
min-height:0!important;
}
.changingProgramImg{
height:53vh;
}
.imgP{
width:100%;
}
.animatedText{
max-width:100%!Important;
}
.changingAboutImg{
min-height:30vh!important;
}
.ChangingContactImg{
min-height:30vh!important;
height:45vh;
}
#alignText{
margin-right:auto;
margin-top:5vh;
}
#alignForm{
margin-right:auto;
}
.fInpt{
width: 80vw!Important;
}
}
@media (min-width: 700px) and (max-width: 800px){
.AnimatedImg img{
width:80%!important;
}
.react-reveal{
margin-left:0!important;
margin-right:0!important;
}
.imgP{
    width: 100vw;
    height: auto;
    margin-left: -10%;
}
.animatedTextOuter{
width:100%!important;
padding:0!important;
text-align:center!important;
}
.animatedText{
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.centeredHomeImage{
    height:62vh!important;
}
.changingProgramImg{
    height:57vh!important;
}
}
@media (min-width: 800px) and (max-width: 900px){
.centeredHomeImage{
height:77vh!important;
}
.changingProgramImg{
height:70vh!important;
}
}
@media (min-width: 900px) and (max-width: 1125px){
.react-reveal{
margin-left:auto;
margin-right:auto;
}
.programIntroText{
padding-top:0!important;
}
.centeredHomeImage{
height:90vh;
}
}

#changingAbout{
min-height:200vh;
width:100%;
min-width:100%;
z-index:-1;
}
.changingAboutText{
    height: auto;
    min-height: 100vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    color:black;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.changingAboutImg{
height:70vh;
min-height:60vh;
width:100%;
min-width:100%;
background-image: url(/static/media/About.91be6c1e.svg);
background-repeat: no-repeat;
background-size: contain;
display:-webkit-flex;
display:flex;
}

.changingAboutText p{
padding-left:0;
}
.aboutTitleSubtitle{
position:relative;
margin-top:auto;
}
.About-left{
width: 48%;
margin-right:auto;
max-width:50%;
}
.About-right{
width:48%;
margin-left:auto;
max-width:50%;
}
.apointment{
    width: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    background: #009898;
    color:white!important;
    padding-top: 10%;
    padding-bottom: 10%;
}
.innerApointment{
margin: auto;
text-align: center;
color:white;
}
.innerApointment a{
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    text-shadow: none;
    background: none;
    box-shadow: none;
        display: inline-block;
    padding: 0.64286em 1.42857em;
    outline: none;
    font-size: 1em;
    line-height: 1.42929;
    cursor:pointer;
    text-decoration: none;
}
.counters{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-content: space-between;
            align-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}
#leftCounter{
    margin-right: auto;
    width:30%;
}
#rightCounter{
   margin-left: auto;
   width:30%;
}
#centerCounter{
   margin-left:auto;
   margin-right:auto;
   width:30%;
}
#leftCounter span, #rightCounter span, #centerCounter span{
    font-size: 4.5em;
    line-height: 1;
    color: #b1dde1;
    font-weight: 600;
    display: block;
    text-align: center;
}
.textCounterAlign{
text-align:center;
}
.facts{
    width: 100%;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
}
.thin{
font-weight:600;
}
.blueh3{
    font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
.noPad{
padding-top:0!important;
}

#info{
width:100%;
height:15%;
display:-webkit-flex;
display:flex;
-webkit-flex-wrap:wrap;
        flex-wrap:wrap;
-webkit-justify-content: space-between;
        justify-content: space-between;
background-color: rgb(0, 152, 152);
}
.download{
text-align:center;
width:40%;
min-height:5vh;
}
.download a{
    position: relative;
    top: 0.5vh;
    bottom: 0.5vh;
    text-decoration: none;
    color: white;
    font-weight: 500;
}
.downloads{
    width: 100%;
    position: relative;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}
#Bprogramming{
    width: 100%;
    height: 30px;
    background-color: black;
    color: white;
    text-align: center;
}
#Bprogramming a{
color:white;
text-decoration:none;
font-weight:500;
top:5px;
bottom:5px;
position:relative;
line-height:0.8em;
}

.articlesOuter{
    width: 100%;
    min-width: 100%;
    min-height: 81vh;
}
.articlesTitle{
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    color: rgb(0, 152, 152);
}
.articlesText{
    width: 80%;
    padding-top: 2%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3em;
}

