body {
  margin: 0;
  padding: 0;
  font-family: "Titillium Web", sans-serif;
  color: #5c6667;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
html {
height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
