#changingHome{
width: 100%;
min-width:100%;
min-height:200vh;
height:auto;
overflow:hidden;
}
#changingHome p{
padding: 0;
margin: 0;
text-align:left;
}
.homee{
padding-top:12vh;
}
#changingHome h4{
font-size: 1.2em;
    font-weight: bold;
    color: #009ba4;
}
.ChangingImg{
width:100%;
min-width:100%;
min-height:100vh;
display: flex;
}
.centeredHomeImage{
    height: 100vh;
    min-height: 30vh;
    width: 100%;
    min-width: 100%;
    background-color: white;
    display: flex;    

}
.changingHomeText{
min-width:80%;
max-width:100%;
min-height:100vh;
background-color:white;
color:black;
overflow:hidden;
}
.changingText p{
padding-top:5%;
text-align:center;
}
.changingText a{
    color: #009ba4;
    position: relative;
    text-decoration: none;
}
.lst{
 margin-left: auto;
    margin-right: auto;
    width: 430px;  
}
.news{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}
#VseNovice{
    color: #009ba4;
    display: block;
    text-decoration: none;
    padding: 10px;
    padding-top: 50px;
    text-align: center;
}
#HomePackagesImage{
background-image: url(./Podnaslovna.svg);
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 80%;
    min-height: 100%;
    width: 80%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.pdingTop{
padding-bottom:0%!important;
padding-top:10%;
}
#HomeIntroLeft{
    width: 48%;
    margin-right: auto;
}
#HomeIntroRight{
    width: 48%;
    margin-left: auto;
}
.first, .second, .third, .fourth{
    width:20%;
    text-align:center;
    border-top: 7px solid #e5f5f6;
}
.news a{
text-decoration:none;
color:#009898;
}
#hhero{
max-width: 100%;
    height: auto;
}
