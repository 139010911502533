#changingContact{
min-width:100%;
background-color: #009ba4;
color: white;
min-height:100vh;
}
.changingContactText{
width:80%;
min-height:auto;
background-color: #009ba4;
color: white;;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding-right:10%;
padding-left:10%;
}
.changingContactText p{
margin:0;
padding: 0;
}
.ChangingContactImg{
    height: 50vh;
    min-height: 60vh;
    width: 100%;
    min-width: 100%;
    background-color:white;
    background-image: url(./Contact.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
}
.fInpt{
width:30vw;
}
#alignForm{
margin-top:5vh;
}
#alignText{
display: flex;
align-items: center;
}
@media only screen and (max-width: 730px) {
#alignText{
margin-right:auto;
}
#alignForm{
margin-right:auto;
width: 80vw!Important;
}
}
